import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/theme/layout'
import Seo from '../../components/seo'

import './legal.scss'

export default function ContentStandardsPolicy() {
    return (
        <Layout>
            <Seo title='Content standards policy' />
            <div className='legal-container'>
                <h1>Content Standards Policy</h1>

                <p>An easy-to-read guide on types of content you can and can’t upload to Qiwio</p>

                <h2>Why this Policy Exists</h2>
                <p>Qiwio’s mission is to harness the power of video to help make business more human ✌. And because businesses rely on Qiwio for creating and sharing videos, we’d like to clarify some ground rules.
                To ensure that the <Link to='/'>qiwio.io</Link> domain remains available for all viewers, we prohibit content that corporations would commonly block on their own networks. Beyond that, we try to be conscientious about
                the kinds of content we help release into the wild; it affects the video-friendly world we’re working hard to create.</p>
                <p>This policy is intended to provide clarity and context around the types of content prohibited on Qiwio. If you violate this policy (or our <Link to='/legal/terms'>Terms of Service</Link>), your account may be suspended
                or terminated. We also believe that context is important. That’s why we try to avoid removing content that is educational, scientific, or artistic in nature.</p>
                <p>If you have any doubts or concerns about the classification of your videos, please email <Link to='mailto: support@qiwio.io'>support@qiwio.io</Link> with your questions. Our team will give you an honest assessment
                of the material and whether or not it should live on Qiwio</p>

                <h2>Content Prohibited in Qiwio</h2>
                <p><strong>Don't</strong> upload content that...</p>
                <p>🚫 is copyrighted by a third-party.</p>
                <p>🚫 violates any law or regulation.</p>
                <p>🚫 contains or depicts nudity, is sexually explicit, or advertises erotic services. This includes pornographic content and content that promotes escort services, erotic massage, fetish fulfillment, or strip clubs.</p>
                <p>🚫 promotes seduction coaching or pickup artist (PUA) techniques.</p>
                <p>🚫 promotes or depicts violence in a gratuitous and non-fictional manner (including but not limited to; war footage, animal cruelty, self-harm, or gore).</p>
                <p>🚫 promotes terrorist organizations, whether or not violence is depicted.</p>
                <p>🚫 incites hatred or displays a demeaning attitude toward a group based on age, disability, ethnicity, religion, race, sexual orientation, or gender identity.</p>

                <h2>Content Approved in Qiwio</h2>
                <p><strong>Do</strong> upload content that...</p>
                <p>✅ is your own and is original!</p>
                <p>✅ abides by all laws and regulations</p>
                <p>✅ is suitable for most, if not all, audiences</p>
                <p>✅ highlights adorable dogs, cats, or other pet-like creatures.</p>
                <p>✅ promotes helpful services or products</p>
                <p>✅ thoughtfully showcases your company culture</p>

                <h2>How to Report Content Violations</h2>
                <p>We have a dedicated channel for reporting content that may be in violation of the Content Standards policy, Terms of Agreement, or potential copyright infringement:</p>
                <p><Link to='mailto: support@qiwio.io'>support@qiwio.io</Link></p>
                <p>Our team actively monitors these inboxes and will aim to evaluate and respond to each claim within 2 weeks of receipt. Each case will be considered in context, using the above guidelines to determine whether
                or not the content should be removed.</p>
            </div>
        </Layout>
    )
}